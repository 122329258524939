import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

import { useAuthContext } from '@contexts/AuthContext';
import { useFeatureFlagVariantKey } from '@custom-hooks/useFeatureFlagVariantKey';
import { FLAGS } from '@utilities/constants/featureFlags';

const useUpdatePaymentMethodStickyButtonCopy = () => {
    const postHogClient = usePostHog();

    const { hasEnteredPaymentDetails } = useAuthContext();

    useEffect(() => {
        if (
            postHogClient &&
            // eslint-disable-next-line no-underscore-dangle
            postHogClient.__loaded
        ) {
            postHogClient.setPersonPropertiesForFlags(
                {
                    has_entered_payment_details: hasEnteredPaymentDetails,
                },
                true,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postHogClient]);

    const { id } = FLAGS.ENABLE_UPDATE_PAYMENT_METHOD_STICKY_BUTTON_COPY;

    const { query } = useRouter();

    const queryOverride = query?.doShowAddPaymentMethodAlternativeButtonCopy as
        | string
        | undefined;

    const variant = useFeatureFlagVariantKey(id);

    let result = false;

    if (queryOverride === 'true') {
        result = true;
    } else if (queryOverride === 'false') {
        result = false;
    } else if (variant === 'test') {
        result = true;
    }

    return {
        doShowAddPaymentMethodAlternativeButtonCopy: result,
    };
};

export { useUpdatePaymentMethodStickyButtonCopy };
