import { Icon } from '@components-design-system/icon';

import $spinner from './spinner.module.scss';

interface SpinnerProps {
    large?: boolean;
}

const Spinner = ({ large }: SpinnerProps) => {
    return large ? (
        <Icon variant="recycling" className={$spinner.spinner} />
    ) : (
        <Icon variant="smallCycle" className={$spinner.spinner} />
    );
};

export { Spinner };
