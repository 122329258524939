export const CLICK_ORIGINS = {
    AUTOPILOT: 'autopilot',
    CATEGORY: 'category',
    CATEGORY_RECOMMENDATIONS: 'category-recommendations',
    CURATED_COLLECTION: 'curated-collection',
    DASHBOARD: 'dashboard',
    DISCOVER: 'discover-home',
    FOR_YOU: 'for-you',
    INVENTORY: 'inventory',
    ONBOARDING: 'onboarding',
    REFILLS: 'refills',
    REFILLS_SUGGESTIONS: 'refills-suggestions',
    PDP: 'pdp',
    PDP_ADD_ON: 'pdp-add-on',
    PDP_RECOMMENDATION: 'pdp-recommendation',
    PRODUCT_QUICK_VIEW_DRAWER: 'product-quick-view-drawer',
    PRODUCT_QUICK_VIEW_DRAWER_RECOMMENDATION:
        'product-quick-view-drawer-recommendation',
    SEARCH_BAR: 'search bar',
    SEARCH_PAGE: 'search-page',
    TRENDING_PRODUCTS: 'trending-products',
    SERVICES_LANDING: 'services-landing',
} as const;

export type ClickOrigin = (typeof CLICK_ORIGINS)[keyof typeof CLICK_ORIGINS];

export type AnalyticsOriginProperties = {
    origin: ClickOrigin;
    originContext: number | string;
    position?: number;
    depth?: number; // how deep into the list relative to other products, as a decimal-based percentage 0-1
    queryId?: string;
};

export const EVENTS = {
    ONBOARDING_SIGNUP_TIMEOUT: 'Onboarding Signup Timeout',
    ONBOARDING_STRIPE_FAILURE: 'Onboarding Stripe Failure',
};

export const DEBOUNCE_TIME_TO_SEND_PRODUCT_VIEW_EVENTS = 4000;
export const VIEW_TIME_TO_TRIGGER_PRODUCT_VIEW_EVENT = 500;
