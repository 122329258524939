import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useAuthContext } from '@contexts/AuthContext';
import { useAreShopAllCategoryRowsEnabled } from '@custom-hooks/featureFlag/useAreShopAllCategoryRowsEnabled';
import { useNewSortOrder } from '@custom-hooks/featureFlag/useNewSortOrder';
import { useReuseDashboard } from '@custom-hooks/featureFlag/useReuseDashboard';
import { useUpdatePaymentMethodStickyButton } from '@custom-hooks/featureFlag/useUpdatePaymentMethodStickyButton';
import { useUpdatePaymentMethodStickyButtonCopy } from '@custom-hooks/featureFlag/useUpdatePaymentMethodStickyButtonCopy';
import {
    getMobileHeaderSubComponentsAtTopOfPage,
    getMobileHeaderSubComponentsOnDownScroll,
    getMobileHeaderSubComponentsOnUpScroll,
} from '@scenes/Dashboard/components/mobileHeader/utilities';
import { SegmentProductsSorted } from '@utilities/analytics';
import sortbyMethodTypes from '@utilities/constants/sortbyMethodTypes';

import { GET_USER_ACTIVE_COUPON } from '../../queries/account';
import { SEARCH_PROMPTS } from './components/mobileHeader/components/searchBarWithPrompts/components/prompts/searchBarPrompts';

export const DashboardContext = createContext();
export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardContextProvider = ({ children }) => {
    const router = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const { userAccountInfo, hasEnteredPaymentDetails } = useAuthContext();
    const { shouldUseNewSortOrder } = useNewSortOrder();
    const [user, setUser] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const defaultSortOrder = shouldUseNewSortOrder
        ? sortbyMethodTypes.NEW
        : sortbyMethodTypes.MOST_POPULAR;
    const [sortbyMethod, setSortbyMethod] = useState(defaultSortOrder);

    const [
        isPaymentCollectionPopupVisible,
        setIsPaymentCollectionPopupVisible,
    ] = useState(false);

    const [userActiveCoupon, setUserActiveCoupon] = useState(null);

    const { areShopAllCategoryRowsEnabled } =
        useAreShopAllCategoryRowsEnabled();

    const { isUpdatePaymentMethodStickyButtonEnabled } =
        useUpdatePaymentMethodStickyButton();

    const { doShowAddPaymentMethodAlternativeButtonCopy } =
        useUpdatePaymentMethodStickyButtonCopy();

    const { showReuseDashboard } = useReuseDashboard();

    const [
        isRefillMinimumOrderValueModalVisible,
        setIsRefillMinimumOrderValueModalVisible,
    ] = useState(false);
    const [getUserActiveCoupon, { data: activeCouponData }] = useLazyQuery(
        GET_USER_ACTIVE_COUPON,
        { fetchPolicy: 'cache-first' },
    );

    const handleIncompleteAddress = () => {
        enqueueSnackbar('Please add a valid delivery address.', {
            variant: 'error',
            persist: true,
        });
        router.push('/dashboard/account');
    };

    // This function shows a toast message with a debounce that will prevent the same message
    // from being shown multiple times. This allows for cases where a state change causes multiple
    // hooks to request a toast message to be shown, but we only want to show it once.
    const toastMessageRef = useRef(null);

    const showToast = (message, config) => {
        if (toastMessageRef.current !== message) {
            toastMessageRef.current = message;
            enqueueSnackbar(message, config);
            setTimeout(() => {
                toastMessageRef.current = null;
            }, 1000);
        }
    };

    // Construct the mobile header sub-components configuration. This includes the names of the sub-components the mobile header should display
    // for the current route, the props (if any) that should be passed to each sub-component, and the index of the sub-components that should
    // display at the top should when the mobile header is collapsed.
    const mobileHeaderSubComponentsConfig = useMemo(() => {
        return {
            subComponentsAtTopOfPage: getMobileHeaderSubComponentsAtTopOfPage(
                router.pathname,
            ),
            subComponentsOnDownScroll: getMobileHeaderSubComponentsOnDownScroll(
                hasEnteredPaymentDetails,
                isUpdatePaymentMethodStickyButtonEnabled,
            ),
            subComponentsOnUpScroll: getMobileHeaderSubComponentsOnUpScroll(
                router.pathname,
            ),
        };
    }, [
        router.pathname,
        hasEnteredPaymentDetails,
        isUpdatePaymentMethodStickyButtonEnabled,
    ]);

    // Ensure default sort order is (re)set when FF loads
    useEffect(() => {
        setSortbyMethod(defaultSortOrder);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldUseNewSortOrder]);

    useEffect(() => {
        if (activeCouponData?.getUserActiveCoupon) {
            setUserActiveCoupon(activeCouponData.getUserActiveCoupon);
        }
    }, [activeCouponData]);

    useEffect(() => {
        if (userAccountInfo?.getUserMembership?.length) {
            const firstSubscription = userAccountInfo.getUserMembership[0];
            setSubscription(firstSubscription);
        }
        if (userAccountInfo?.getUserProfile) {
            setUser(userAccountInfo.getUserProfile);
        }
        if (userAccountInfo?.getUserProfile.id) {
            getUserActiveCoupon({
                variables: {
                    user_id: userAccountInfo?.getUserProfile?.id,
                },
            });
        }
        if (
            userAccountInfo?.getUserProfile &&
            !userAccountInfo.getUserProfile?.location?.city_id
        ) {
            handleIncompleteAddress();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAccountInfo]);

    useEffect(() => {
        if (activeCouponData) {
            setUserActiveCoupon(activeCouponData.getUserActiveCoupon);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userActiveCoupon]);

    const handleApplySortbyMethod = useCallback(
        (s) => {
            setSortbyMethod(s);
            SegmentProductsSorted(s);
        },
        [setSortbyMethod],
    );

    const userIsCancelled = useMemo(() => {
        if (!subscription) return false;
        return subscription.status === 'cancel';
    }, [subscription]);

    const userIsPaused = useMemo(() => {
        if (!subscription) return false;
        return subscription.status === 'pause';
    }, [subscription]);

    const cityId = useMemo(() => {
        if (!user) return null;
        return user.location?.city_id ? Number(user.location?.city_id) : null;
    }, [user]);

    const refillDay = useMemo(() => {
        if (!user) return null;
        return user.refill_schedule?.delivery_day;
    }, [user]);

    const lockTime = useMemo(() => {
        if (!user) return null;
        const lockDayOfWeek =
            user.refill_schedule?.delivery_day_schedule?.lock_day_of_week;
        const lockTimeFormatted =
            user.refill_schedule?.delivery_day_schedule?.lock_time_formatted;

        if (!lockDayOfWeek || !lockTimeFormatted) return null;

        return `${lockDayOfWeek} at ${lockTimeFormatted}`;
    }, [user]);

    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const contexts = {
        areShopAllCategoryRowsEnabled,
        cityId,
        handleApplySortbyMethod,
        isPaymentCollectionPopupVisible,
        isRefillMinimumOrderValueModalVisible,
        isUpdatePaymentMethodStickyButtonEnabled,
        doShowAddPaymentMethodAlternativeButtonCopy,
        lockTime,
        mobileHeaderSubComponentsConfig,
        refillDay,
        searchPrompts: SEARCH_PROMPTS,
        setIsPaymentCollectionPopupVisible,
        setIsRefillMinimumOrderValueModalVisible,
        showReuseDashboard,
        showToast,
        sortbyMethod,
        subscription,
        user,
        userActiveCoupon,
        userIsCancelled,
        userIsPaused,
    };

    return (
        <DashboardContext.Provider value={contexts}>
            {children}
        </DashboardContext.Provider>
    );
};
